import { isMobile } from '../../../libs/userAgent';
import './index.scss'

const Footer = (): JSX.Element => {
  return (
    <>
      <div className={isMobile() ? "mfooter footer" : "footer"}>
        <div className="logo">
          <a href="/">
            <img src={require('../../../assets/img/footer_logo.png').default} alt="" />
          </a>
        </div>
        <div className="nav">
          <div className="socialGroup">
            <div className="item">
              <a href="https://www.tiktok.com/@blink.bloc" >
                <img src={require('../../../assets/img/tiktok.png').default} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="https://www.instagram.com/blinkbloc_agency/" >
                <img src={require('../../../assets/img/ins.png').default} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="https://www.linkedin.com/company/blinkbloc">
                <img src={require('../../../assets/img/in.png').default} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="https://x.com/BlinkBloc">
                <img src={require('../../../assets/img/twitter.png').default} alt="" />
              </a>
            </div>
          </div>
          <h4>BlinkBloc Network Limited. </h4>
          <p>16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex</p>
          <div className="sever">
            <a href="/service">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;