import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { isMobile } from "../../libs/userAgent";

interface LineSVGProps {
  triggerAnimation: boolean;
  strokeColor?: string;
}

const LineSVG = ({ triggerAnimation, strokeColor = "#F4D250" }: LineSVGProps): JSX.Element => {
  const pathRef = useRef<SVGPathElement>(null);

  const { strokeDashoffset } = useSpring({
    from: { strokeDashoffset: 1000 },
    to: { strokeDashoffset: triggerAnimation ? 0 : 1000 },
    config: { duration: 3000 },
    reset: false,
  });

  return (
    <svg
      style={{
        width: isMobile() ? '206px' : '2.76rem',
        height: isMobile() ? '17px' : '.28rem'
      }}
      viewBox="0 0 276 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.path
        ref={pathRef}
        d="M3.5 10.6443C51.6618 7.0765 102.568 5.16046 151.854 3.45879C161.805 3.11519 188.496 2.26472 181.743 4.40425C177.605 5.71505 141.715 11.819 139.636 12.1571C123.31 14.8129 109.75 16.6963 92.9485 18.9014C86.9583 19.6875 78.9895 19.6952 74.8406 21.1705C70.4581 22.7288 88.3946 20.2786 95.1302 19.7838C123.427 17.7053 152.071 15.0362 181.088 13.9219C209.559 12.8286 160.867 20.6074 157.526 21.1705C156.122 21.407 125.484 25.6249 128.073 24.9208C166.215 14.5495 223.561 13.7069 272.5 11.7789"
        stroke={strokeColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeDasharray="1000"
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
};

interface Props {
  strokeColor?: string;
}

const LineSVGContainer: React.FC<Props> = ({ strokeColor }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const element = containerRef.current;
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting && !hasAnimated) {
        setTriggerAnimation(true);
        setHasAnimated(true);
      }
    }, {
      root: null,
      threshold: 0.5
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [hasAnimated]);

  return (
    <div ref={containerRef}>
      <LineSVG triggerAnimation={triggerAnimation} strokeColor={strokeColor} />
    </div>
  );
};

export default LineSVGContainer;