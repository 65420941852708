import { GoogleSpreadsheet } from 'google-spreadsheet';
import { useState } from 'react';
import { isMobile } from '../../../libs/userAgent';
import LoadingSpinner from '../../loading/loading';
import Modal from '../../modal';
// import { GoogleSpreadsheet } from 'google-spreadsheet';
import './applyform.scss';

interface FormData {
  username: string;
  follower: '≥1000' | '≤1000';  // 单选框的选项之一
  inviteCode: string;
  insUserName?: string;  // 可选字段
  emailAddress?: string; // 可选字段
  referred: string;
  formType: string  // 设置初始值
}

interface FormErrors {
  username?: string;
  follower?: string;
  inviteCode?: string;
  referred?: string;
}

const CreatorForm = (): JSX.Element => {
  const [formData, setFormData] = useState<FormData>({
    username: '',
    follower: '≤1000',  // 初始值设置为某个选项
    inviteCode: '',
    insUserName: '',
    emailAddress: '',
    referred: '',
    formType: 'creatorForm',  // 设置初始值
  });

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitIsSuccess, setSubmitIsSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: FormErrors = {};

    // 只验证必填字段
    if (!formData.username.trim()) {
      newErrors.username = 'username is required';
    }
    if (!formData.inviteCode.trim()) {
      newErrors.inviteCode = 'inviteCode is required';
    }
    if (!formData.referred.trim()) {
      newErrors.referred = 'referred is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // 提交表单数据
    console.log('Form submitted:', formData);

    await addDataToSheet(formData)

    // // 清除错误状态


  };


  function convertFormDataToObject(formData: FormData): { [header: string]: string | number | boolean } {
    return {
      ...formData
    };
  }

  const addDataToSheet = async (data: FormData) => {
    setIsLoading(true);

    const SPREADSHEET_ID: any = '1WnGRnQdjFAdfP6OWsjsHbxrDDlZe11j546pN0g56hDk';
    const SHEET_ID: any = 0;
    const CLIENT_EMAIL: any = 'submitaddress@bionic-spot-363106.iam.gserviceaccount.com';
    const PRIVATE_KEYs: any = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDVpZXnNiPl+IGh\nftq2DY8oMsHl5t62vqp8DQiKfkBNkpy000NdhoyHmGFWlqNr3YqIC9Unw1vAdg/M\nHkGRmZhNxQn/32DsYfABmK+FM2r6trzh9lzdh1xBi0xRiScjp23PIlp1fO03gMba\nYYW7BgqeFkKFCJJqPtML9J5WXs/CHIbW3LP+MQW4QjpvmXAYIYUa6QmEppR8IiWA\nF9tmwK/7l9oWkRn+/I6y0U3NQHWga1qUFcTkFmbiJawCOsroKatlThKKChecqBpR\nQGbm4E4TNfDT3tMdifwg1O8uXe2lWaTsrgw9JBESkcuWHRQNUQoZPxkeslFS9bzT\nUXH7D4YLAgMBAAECggEABJEDJjnhPW/jjzON6y/KyMFIiMv+zt6wMQ5d0LOLrId0\nQcmf+jTGWbq1gee7Bc9OFUPjewH710juuUjyDEb6RddENDn81Ol+F6jdeG8LBm+3\nd11ldTAIsqkOjKcnCnQpWT8Lul7rT/hHALF8tNBQWuTir1F36z02FAk6k2HNaB4i\nn1i8WZXGjL2hciOOa/gLnrUjhAPcfqM0m+nb+mBi4OtQX7P3S66K8D0Lb1kOEaUL\n/CoDjA9bggQeSgoGfs4Uv4DxCnv+Rt53ZRTWaCV6ZDWCim248OBywutvvhUEQ2bb\nV9VuZ8JWOZzWK376ggK+5jjb5hmdteaQWik+I/jmKQKBgQD5ndjxI7bpvSrzeWZ5\nxfUqzYFQM7AbdRx+SSQMSpsodWCbZF3CVzJbrnsWUy2MP55RLQ1Admki1e3hW9dr\nhv0Z+SChvO5+TuJV94CRXZVNAQ06UWUXMVybhI/BI6yteVNBVzJObPFa25JZOA4V\nuUIbmeF0upg9quHREZbehjo7NQKBgQDbHEGwhOym8XQAcJq0naB6SgR8xpaoAYiF\npahFAVEE64dcMqgAbGx+qCZak05fiKQLmPyW5L/X/b89KxeszCSrXlhEsxbe23mx\nQ6JSPU7YZNfwqFGaOgVb7wW5TuyvRZa7OJVNyjbbbMxGOM9qA5RHSDKM9xXeDirx\nSiipXjmkPwKBgEzfbss8m306c7rNvWBtNYa8CUIA2RBrrZ26uq5KX6RDGcBhVXqf\nTfPsNR33+cQCZ6hKPpWGofRHe932Yc5MoS/ZiFInFC+z7YJaGa8q6erwEvnNzVsq\nKYH/fiIV8XzvK1OpHeYHKQE5M8XkPHo2NoS3jDZdrLr5IFg5mYPoRJCtAoGAbakW\nt2HPjz4I8K9qvjqlto6U8F3zyOH7kef275JZGj1G4b34lz2sjzdY+rv+3ZFliOho\ngpy55z/4FoUatED3mUz+oGOBbsgpf6557u8AJw1RhgC31phzVi+qjbQSgSYy6kDH\neMdb7TXmhr6oEEb6TzKzGhoPt7MfiuOythGSh8ECgYAzh9om7ikFt56NBi6RLxBo\nwTzuUWbObJZnzujPcyf8LHY612T0jZWj1Mp3qNGFYIxcXB2zC/EjchaH3Leo6PeV\nbE/Co26vVzw1e8KlP94fYgKS1OrS2SSN/1H6KrIgjixqR8o0J5Mo5t4ATnV2lz1s\n08Zq3A2+cN8/7ScbS9pgig==\n-----END PRIVATE KEY-----\n';
    const PRIVATE_KEY = PRIVATE_KEYs.replace(/(\\r)|(\\n)/g, '\n')
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const convertedData = convertFormDataToObject(formData);
      const result = await sheet.addRow(convertedData)
      console.log('%c🀆 result', 'color: #ff0000; font-size: 20px;', result);

      setErrors({});

      // 重置表单
      setFormData({
        username: '',
        follower: '≤1000',  // 初始值设置为某个选项
        inviteCode: '',
        insUserName: '',
        emailAddress: '',
        referred: '',
        formType: 'creatorForm',  // 设置初始值
      });
      setIsLoading(false);
      setSubmitIsSuccess(true)
      openModal()

    } catch (e) {
      console.error('Error: ', e);
      setSubmitIsSuccess(false)
      setIsLoading(false);
      openModal()
    }

  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={isMobile() ? "mApplyform applyform " : "applyform"}>
        <div className="banner Agent">
          <h1>Creator Apply</h1>
          <p>To join our TikTok Live Creator Network…</p>
          <a href="https://www.tiktok.com/t/ZS2yE8pDp/
" className='btn'>
            CLICK HERE TO APPLY IN-APP
          </a>
        </div>
        <div className="form">
          <p className='desc'>
            Hi, future family member! Welcome to our loving family!<br />
            You love Tik Tok and wanna being a superstar? We gotcha!<br />
            Creators over 18 years old can use our tips and coaching to boost your success on Tik Tok!<br />
            Make real 💴 ! Cash out weekly from TikTok.
            OK, Let's go! Join our family.
          </p>
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className='form-item'>
                <label>
                  Tikok Username/Handle
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
                {errors.username && <span className="error">{errors.username}</span>}
              </div>

              <div className='form-item'>
                <label>Creator Follower Number</label>
                <div className='radioBox'>
                  <label>
                    <input
                      type="radio"
                      name="follower"
                      value="≤1000"
                      checked={formData.follower === '≤1000'}
                      onChange={handleChange}
                    />
                    Under 1K, check this box and our staff will reach out.
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="follower"
                      value="≥1000"
                      checked={formData.follower === '≥1000'}
                      onChange={handleChange}
                    />
                    Over 1K, we will send out the invitation once we received your code.
                  </label>
                </div>
                {errors.follower && <span className="error">{errors.follower}</span>}
              </div>

              <div className='form-item'>
                <label>
                  Tik Tok Invitation Code
                </label>
                <input
                  type="text"
                  name="inviteCode"
                  value={formData.inviteCode}
                  onChange={handleChange}
                />
                {errors.inviteCode && <span className="error">{errors.inviteCode}</span>}
                <div className='tip'>
                  <p>
                    👉Location: TikTok {'>'} Settings and privacy {'>'} Creator tools {'>'} LIVE Center {'>'} Creator Network Center <br />
                    Or please follow the steps as the picture above
                  </p>
                  <img src={require('../../../assets/img/tip.png').default} alt="Tip" />
                </div>
              </div>

              <div className='form-item'>
                <label>
                  Instagram Username (Optional)
                </label>
                <input
                  type="text"
                  name="insUserName"
                  value={formData.insUserName}
                  onChange={handleChange}
                />
              </div>

              <div className='form-item'>
                <label>
                  Email Address (Optional)
                </label>
                <input
                  type="text"
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={handleChange}
                />
              </div>

              <div className='form-item'>
                <label>
                  Who referred you to BlinkBloc?
                </label>
                <input
                  type="text"
                  name="referred"
                  value={formData.referred}
                  onChange={handleChange}
                />
                {errors.referred && <span className="error">{errors.referred}</span>}
              </div>

              <button className='submit' type="submit">
                {
                  isLoading
                    ?
                    <LoadingSpinner />
                    : 'Apply Now'
                }


              </button>
              <p className='desctip'>❗️We NEVER share or sell your data, this info will only contact you through email or Instagram for the live streaming business.</p>
            </form>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} title="" isSuccess={submitIsSuccess}>

        {
          submitIsSuccess
            ? <>
              <div className="contion">
                <h2>
                  <span>👍</span>
                  Well Done!
                </h2>
                <p>
                  Thank you for joining us! <br />
                  We’ll invite you in-app within 2 workdays.
                </p>

              </div>
            </>
            : <>
              <div className="contion">
                <h2>
                  <span>😣</span>
                  Uh-oh!
                </h2>
                <p>
                  Something needs to be fixed! Please try to submit it again! We appreciate your work!
                </p>

              </div>
            </>
        }
      </Modal>
    </>
  );
}

export default CreatorForm;
