import { isMobile } from '../../../libs/userAgent';
import LineSVGContainer from '../line';
import './index.scss'

const offerList = [
  {
    title: '1-on-1 Guidance',
    content: 'Get 1-on-1 support from a dedicated Creator Manager to perfect your strategy and content right from day 1.'
  },
  {
    title: 'Exclusive Campaigns & Battles',
    content: "Participate in BlinkBloc’s campaigns and cross-agency battles to boost your account and engage your audience."
  },
  {
    title: 'Traffic Support',
    content: "We help you get more views and engagement through smart promotions and collaborations."
  },
  {
    title: 'Brand Partnerships',
    content: "Connect with brands for sponsorships and collaborations that enhance your content and boost your earnings."
  },
  {
    title: "Milestone Rewards",
    content: "Get rewards as you achieve significant milestones in followers, engagement, and content creation."
  },
  {
    title: 'Community & Networking',
    content: "Join a supportive community of creators, offering networking opportunities and collaborative growth."
  }
]

const teamArr = [
  {
    name: 'Liz',
    avatar: require('../../../assets/img/KevinMa.png').default
  },
  {
    name: 'Kevin Ma',
    avatar: require('../../../assets/img/KevinMa2.png').default
  },
  {
    name: 'Ashley',
    avatar: require('../../../assets/img/Ashley.png').default
  },
  {
    name: 'Joshua',
    avatar: require('../../../assets/img/Joshua.png').default
  },
  {
    name: 'Johnny',
    avatar: require('../../../assets/img/Johnny.png').default
  }
]

const AboutComp = (): JSX.Element => {
  return (
    <>
      <div className={isMobile() ? "mAbout about" : "about"}>
        <div className="banner">
          <div className="title">
            <div className="svg">
              <LineSVGContainer />
            </div>
            <h1>About us</h1>
          </div>
        </div>
        <div className="explain">
          <h2>Your TikTok journey starts here <br />
            Grow, engage, and monetize with BlinkBloc</h2>
          <p><span>BlinkBloc</span> is your launchpad for success on TikTok. <br /> Whether you’re just starting or ready to take your content to the next level, we’re here to help you thrive in the world’s most vibrant social platform. <br /><span>Our mission is simple: to empower creators like you to turn your passion into profit.</span><br />
            At BlinkBloc, we understand that building a presence on TikTok takes time, dedication, and the right strategy. That’s why we’re committed to guiding you every step of the way. We’re not only a live agency, we also connect you with brands that resonate with your audience, helping you grow your influence and unlock new revenue streams.<br />
            Our innovative approach is designed specifically for creators who are ready to invest their time in TikTok and see real results. We don’t just follow trends—we set them, driving cultural relevance for creators.
            As part of a network of cutting-edge agencies, BlinkBloc stays ahead of the curve, using the latest in media, data, and AI to ensure your success. <br /><span>Ready to make your mark? Join BlinkBloc, and let’s create something extraordinary together.</span>
          </p>
        </div>
        <div className="offer">
          <h2>What We Can Offer?</h2>
          <div className="list">
            {
              offerList.map((ele, index) =>
                <div className="item" key={index}>

                  <h3>{ele.title}</h3>
                  <p>{ele.content}</p>
                </div>

              )
            }

          </div>
        </div>
        <div className="picWall">
          <div className="item">
            <img src={require('../../../assets/img/wall1.png').default} alt="" />
          </div>
          <div className="item">
            <img src={require('../../../assets/img/wall2.png').default} alt="" />
          </div>
          <div className="item">
            <img src={require('../../../assets/img/wall3.png').default} alt="" />
          </div>
        </div>
        <div className="story">
          <h2>OUR STORY</h2>
          <p>
            BlinkBloc was born from the experience of working with over 1000+ creators, each with their unique style and vision. In the beginning, many creators start with a simple passion, connecting with communities, and making a bit of money. These initial steps are crucial in evolving into a full-time presence in the network. <br />
            As we navigated this journey, we discovered the key to success lies in matching creators with the right brands. We realized that each creator has a niche, and finding the perfect brand fit is essential for true growth and monetization. <br />
            BlinkBloc emerged from this realization and is dedicated to bridging the gap between creators and brands. We provide personalized support to help creators refine their strategies, connect with brands that align with their niche, and turn their passion into a thriving career.
            Since then, BlinkBloc has become a beacon for creators, helping them to grow, engage, and succeed in the ever-evolving world of social media.

          </p>
        </div>
        <div className="meet">
          <div className="content">
            <h2>Meet our team</h2>
            <div className="card-container list">
              <div className="card-row">
                {
                  teamArr.slice(0, 3).map((ele, index) => (
                    <div key={`meet${index}`} className="card-item">
                      <div className="avatar">
                        <img src={ele.avatar} alt="" />
                      </div>
                      <h3>{ele.name}</h3>
                    </div>
                  ))
                }
              </div>
              <div className="card-row center-row">
                {
                  teamArr.slice(3, 5).map((ele, index) => (
                    <div key={`meet${index + 3}`} className="card-item">
                      <div className="avatar">
                        <img src={ele.avatar} alt="" />
                      </div>
                      <h3>{ele.name}</h3>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <a href="/apply" className='btn'>
            {/* <img src={require('../../../assets/img/join_btn.png').default} alt="" /> */}

            Join Now
          </a>
        </div>
      </div>

    </>
  );
}

export default AboutComp;