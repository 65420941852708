import { isMobile } from '../../libs/userAgent';
import './index.scss'
const Service = (): JSX.Element => {
  return (
    <>
      <div className={isMobile() ? "mContent content" : "content"}>
        <h1 className="title">
          Terms of Service
        </h1>
        <div className="date">
          Effective Date:2024/04/01
        </div>
        <h2>
          1. Acceptance of Terms
        </h2>
        <p>By accessing or using the BlinkBloc platform and services, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our services.</p>
        <h2>2. Overview</h2>
        <p>BlinkBloc is a creator agency based in Delaware, dedicated to helping creators thrive and monetize their content. Our services include recruitment, brand partnerships, and growth management.</p>
        <h2>3. User Information</h2>
        <p>We value your privacy and do not collect personal information beyond what is necessary for the provision of our services. Any personal information provided to us is handled in compliance with applicable laws and regulations.</p>
        <h2>4. Compliance with Laws</h2>
        <p>As a company incorporated in Delaware, BlinkBloc adheres to the laws and regulations of the state of Delaware. All services and operations are conducted in accordance with Delaware law.</p>
        <h2>5. Services</h2>
        <p>
          BlinkBloc provides the following services: <br />
          Recruitment of creators to join our agency. <br />
          Management of creator growth and monetization. <br />
          Connection with brands for partnerships and collaborations. <br />
          Access to internal campaigns, competitions, and milestone rewards.
        </p>
        <h2>6. Revenue Sharing</h2>
        <p>BlinkBloc operates on a revenue-sharing model. Revenue shares are distributed based on the agreed terms and conditions for each specific partnership or collaboration.</p>
        <h2>7. Intellectual Property</h2>
        <p>All content, trademarks, and other intellectual property related to BlinkBloc are owned by BlinkBloc or its licensors. You may not use, reproduce, or distribute any of this intellectual property without explicit permission.</p>
        <h2>8. Termination</h2>
        <p>BlinkBloc reserves the right to terminate your access to our services at any time if you violate these Terms of Service or if we determine, at our sole discretion, that such action is necessary.</p>
        <h2>9. Limitation of Liability</h2>
        <p>
          BlinkBloc is not liable for any indirect, incidental, or consequential damages arising from the use of our services. Our total liability is limited to the maximum extent permitted by law.
        </p>
        <h2>10. Changes to Terms</h2>
        <p>BlinkBloc reserves the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Continued use of our services constitutes acceptance of the updated terms.</p>
        <h2>11. Contact Information</h2>
        <p>
          For any questions or concerns regarding these Terms of Service, please contact us at: contact@blinkbloc.live
        </p>
        <h3>BlinkBloc</h3>
        <p>
          BlinkBloc Network Limited. <br />
          Address: 16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex 19706 <br />
          Email: <a href="meailTo:contact@blinkbloc.live">contact@blinkbloc.live</a>

        </p>
      </div>

    </>
  );
}

export default Service;