import { isMobile } from '../../libs/userAgent';
import './index.scss'

const Privacypolicy = (): JSX.Element => {
  return (
    <>
      <div className={isMobile() ? "mContent content" : "content"}>
        <h1 className="title">
          privacy policy
        </h1>
        <div className="date">
          Effective Date:2024/04/01
        </div>
        <h2>
          1. Introduction
        </h2>
        <p>
          BlinkBloc is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our services. By using BlinkBloc, you agree to the practices described in this policy.
        </p>
        <h2>2. Information We Collect</h2>
        <h4>Personal Information:</h4>
        <p>
          BlinkBloc is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our services. By using BlinkBloc, you agree to the practices described in this policy.
        </p>
        <h4>Usage Data:</h4>
        <p>
          We may collect non-personal information about your interactions with our services, such as IP addresses, browser types, and usage patterns. This information helps us improve our services and understand how they are used.
        </p>


        <h2>3. How We Use Your Information</h2>
        <h4>Service Provision:</h4>
        <p>
          Your information is used to provide, manage, and improve our services, including recruitment, brand partnerships, and growth management.
        </p>
        <h4>Communication:</h4>
        <p>
          We may use your contact information to communicate with you regarding your engagement with BlinkBloc, including updates, promotions, and service-related information.
        </p>
        <h4>Analytics:</h4>
        <p>
          Usage data is used to analyze trends, track user interactions, and enhance the functionality and user experience of our services.
        </p>
        <h2>4. Data Protection and Security</h2>
        <h4>Data Security:</h4>
        <p>
          We implement appropriate security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
        </p>
        <h4>Data Retention:</h4>
        <p>
          We retain personal information only for as long as necessary to fulfill the purposes for which it was collected, as required by law, or as otherwise stated in this Privacy Policy.
        </p>
        <h2>5. Sharing Your Information</h2>
        <h4>Third-Party Service Providers:</h4>
        <p>
          We may share your information with third-party service providers who assist us in delivering our services. These providers are contractually obligated to protect your information and use it only for the purposes specified.
        </p>
        <h4>Legal Requirements:</h4>
        <p>
          We may disclose your information if required to do so by law, or if we believe that such action is necessary to comply with legal obligations, protect our rights, or enforce our terms.
        </p>

        <h2>6. Your Rights and Choices</h2>
        <h4>Access and Correction:</h4>
        <p>
          You have the right to access, update, or correct your personal information. If you wish to exercise these rights, please contact us using the information provided below.
        </p>
        <h4>Opt-Out:</h4>
        <p>
          You may opt out of receiving promotional communications from us by following the instructions in those communications or contacting us directly.
        </p>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on our website, and the updated policy will be effective immediately upon posting. Your continued use of our services constitutes acceptance of the revised policy.
        </p>
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:
        </p>

        <h3>BlinkBloc</h3>
        <p>
          BlinkBloc Network Limited. <br />
          Address: 16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex 19706 <br />
          Email: <a href="meailTo:contact@blinkbloc.live">contact@blinkbloc.live</a>

        </p>
      </div>
    </>
  );
}

export default Privacypolicy;