import React from 'react';
import './CustomCollapse.scss';


interface Panel {
  id: string;
  header: string;
  content: React.ReactNode;
}

interface CustomCollapseProps {
  panels: Panel[];
  activeId: string | null;
  togglePanel: (id: string) => void;
}

const CustomCollapse: React.FC<CustomCollapseProps> = ({ panels, activeId, togglePanel }) => {
  return (
    <div className="custom-collapse">
      {panels.map((panel) => (
        <div key={panel.id} className={`custom-panel ${activeId === panel.id ? 'active' : ''}`}>
          <div
            className="custom-panel-header"
            onClick={() => togglePanel(panel.id)}
          >
            <img className='jiantou' src={require('../../../assets/img/jiantou.png').default} alt="" />
            {panel.header}
          </div>
          <div
            className={`custom-panel-content`}
          >
            {panel.content}
          </div>
        </div>
      ))}
    </div>
  );
};
export default CustomCollapse;
