import React from 'react';
import { useSpring, animated } from 'react-spring';
import './index.scss';  // Create this file for CSS styling

const LoadingSpinner: React.FC = () => {
  const spinningAnimation = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    loop: true,
    config: { duration: 1000 },
  });

  return (
    <div className="loading-container">
      <div className="cont">
        <animated.div className="spinner" style={spinningAnimation} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
