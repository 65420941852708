import { useState } from "react";
import { isMobile } from "../../../libs/userAgent";
import CustomCollapse from "../CustomCollapse/CustomCollapse";
import LineSVGContainer from "../line";
import './index.scss'


const leftpanels = [
  {
    id: 'panel1',
    header: 'What should I do first after joining BlinkBloc?',
    content: <p>Welcome aboard! Please check your DM or Email, we will reach you for the first step. The first month of the agency is to find your path, tag your audience, and build your family. We have a routine of webinars in the agency and encourage you to join us, willing to share, and don’t hesitate to ask your questions!
    </p>,
  },
  {
    id: 'panel3',
    header: 'How do I reach my Creator Manager?',
    content: <p>Your creator manager will reach you! He/She might reach you via DM or on our community within 3 workdays, please check your message. We’re committed to providing you with personalized support, so don’t hesitate to reach out with any questions or ideas.
    </p>,
  },
  {
    id: 'panel5',
    header: "What's in it for me?",
    content: <p>BlinkBloc offers you exclusive access to brands, monetization strategies, and a community of fellow creators. We help you grow your audience, increase engagement, and turn your content into a sustainable income. Plus, our team is here to support you every step of the way.
    </p>,
  },
  {
    id: 'panel7',
    header: "Can I leave BlinkBloc anytime I want?    ",
    content: <p>At BlinkBloc, we believe in flexibility. While we encourage long-term partnerships for maximum growth, you’re not locked into a contract. Please let us know if you decide to part ways, and we’ll ensure a smooth transition.
    </p>,
  },
  {
    id: 'panel9',
    header: "How do we make money from going live on TikTok?    ",
    content: <p>
      Going live on TikTok opens multiple revenue streams. You can earn through gifts from your viewers, brand sponsorships, and special live events we organize. We’ll show you how to engage your audience during live sessions to maximize earnings.
    </p>,
  },
];

const rightpanels = [
  {
    id: 'panel2',
    header: 'How can BlinkBloc help me monetize?    ',
    content: <p>
      BlinkBloc offers a range of monetization strategies tailored to your content and audience. We connect you with brand deals, help you create paid partnerships, and guide you in optimizing live streams and content creation to generate consistent income.
    </p>,
  },
  {
    id: 'panel4',
    header: 'What kind of brands does BlinkBloc work with?    ',
    content: <p>BlinkBloc partners with various brands across various industries, from fashion and beauty to tech and lifestyle. We match you with brands that align with your content style and audience, ensuring authentic and lucrative collaborations.    </p>,
  },
  {
    id: 'panel6',
    header: 'Will I get help with content creation?    ',
    content: <p>
      Absolutely! We provide resources, tips, and assistance crafting content that resonates with your audience. Your Creator Manager will work closely with you to develop ideas, optimize your videos, and ensure your content stands out.
    </p>,
  },
  {
    id: 'panel8',
    header: "What if I don’t hit my goals?    ",
    content: <p>
      We understand that growth can be challenging, and we’re here to help. If you’re not hitting your targets, we’ll review your strategy, identify areas for improvement, and adjust our approach to get you back on track. Your success is our priority.
    </p>,
  },
  {
    id: 'panel10',
    header: 'Why should I join your agent program?    ',
    content: <p>Joining our agent program opens the door to a world of opportunity. As a BlinkBloc agent, you gain access to our backstage, allowing you to build strong, profitable partnerships. We provide you with the tools, resources, and support to succeed, including training and a dedicated team to help you grow your roster.
      Plus, our commission structure rewards your hard work, ensuring that the more successful you are, the more you earn.
      Whether you’re looking to expand your influence or enter the creator economy, our agent program is the perfect platform to elevate your start-up thinking of the TikTok livestream career.
    </p>,
  },
];


const ApplyComp = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<any | null>(null);

  const togglePanel = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className={isMobile() ? "mApply apply" : "apply"}>
        <div className="banner">
          <div className="title">
            <div className="svg">
              <LineSVGContainer />
            </div>
            <h1>Apply</h1>
          </div>
        </div>
        <div className="question">
          <div className="applylist">
            <div className="item">
              <h2>Creator Apply</h2>
              <p>Make real 💴 !  Cash out weekly from TikTok.</p>
              <a href="/creator" className='btn'>
                Apply Now
              </a>
            </div>
            <div className="item">
              <h2>Agent Apply</h2>
              <p>Join BlinkBloc as an agent: recruit top creators, manage their success, to get $10000 per month!</p>
              <a href="/agent" className='btn'>
                Apply Now
              </a>
            </div>
            <div className="item">
              <h2>Ambassador</h2>
              <p>Become a BlinkBloc Ambassador: connect with brands, expand our network, and earn a share of the revenue!</p>
              <a href="/ambassador" className='btn'>
                Apply Now
              </a>
            </div>
          </div>
          <h2 className="subtitle">The question you may concern</h2>
          <div className="list">
            <div className="left">
              <CustomCollapse panels={leftpanels} activeId={activeIndex} togglePanel={togglePanel} />
            </div>
            <div className="right">
              <CustomCollapse panels={rightpanels} activeId={activeIndex} togglePanel={togglePanel} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyComp;
