/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/img/logo.png';

import './index.scss';




const Header = (): JSX.Element => {
  const location = useLocation();  // Get current location (URL path)

  // Helper function to determine if a path is active
  const isActive = (path: string) => location.pathname === path;
  return (
    <>
      <div className='header'>
        {/* <div className='header-blur' /> */}
        <div className='header-wrap'>
          <div className='header-logo'>
            <a href='/'>
              <img src={Logo} alt='Logo' />
            </a>
          </div>
          <nav className='header-nav'>
            <Link
              className={`header-nav-item ${isActive('/') ? 'active' : ''}`}
              to='/'
              id='nav-banner'
            >
              <div className='header-nav-item-title'>
                Home
              </div>
            </Link>
            <Link className={`header-nav-item ${isActive('/about') ? 'active' : ''}`} to='about' id='nav-banner'>
              <div className='header-nav-item-title'>
                About us
              </div>
            </Link>
            <a className='header-nav-item' href='https://blinkbloc.gitbook.io/blinkbloc/
' id='nav-banner'>
              <div className='header-nav-item-title'>
                Blog
              </div>
            </a>
            <Link className='apply header-nav-item' to='apply' id='nav-banner'>
              <div className='header-nav-item-title'>
                Apply
              </div>
            </Link>
            <div className="socialGroup">
              <a href="https://www.tiktok.com/@blink.bloc" className="item">
                <img src={require('../../../assets/img/tiktok.png').default} alt="" />
              </a>
              <a href="mailTo:contact@blinkbloc.live
" className="item">
                <img src={require('../../../assets/img/email.png').default} alt="" />
              </a>
            </div>

          </nav>

        </div>
      </div>
    </>
  );
}

export default Header;
