export const isMobile = (): boolean => {
  let isMobile: boolean = false;

  const _isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  const WWidth = window.screen.width;

  console.log("%c🀂 ", "color: #00a3cc; font-size: 20px;", WWidth);

  if (_isMobile && WWidth < 532) {
    isMobile = true;
  }

  return isMobile;
};
