import React from 'react';
// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import './index.scss';
// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css"
import team1 from '../../../assets/img/team1.png'
import team2 from '../../../assets/img/team2.png'
import team3 from '../../../assets/img/team3.png'
import team4 from '../../../assets/img/team4.png'
import team5 from '../../../assets/img/team5.png'
import team6 from '../../../assets/img/team6.png'
import LineSVGContainer from '../line';
import { isMobile } from '../../../libs/userAgent';

const Home: React.FC = () => {

  SwiperCore.use([Autoplay, Navigation]);

  return (
    <div className={isMobile() ? 'mHome home' : "home"}>
      <div className="banner">
        <div className="bg">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            poster={require('../../../assets/img/banner.png').default} // Optional poster for loading image
          >
            <source src={require('../../../assets/img/banner.mp4').default} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        </div>
        <h1>
          BlinkBloc. <br />
          Shine Together
        </h1>
        <img className='tiktoklive' src={require('../../../assets/img/tiktoklive.png').default} alt="tiktoklive" />

      </div>
      <div className="Live">
        <div className="title">
          <div className="svg">
            <LineSVGContainer />
          </div>
          <h1>Live Partner of Tik Tok</h1>
        </div>
        <p>
          <span>BlinkBloc</span> is a creator-based agency, we take high value of every creator, and we trust everyone has the potential to grow from nobody to a superstar.
        </p>
        <div className="list">
          <div className="item">
            <h2>10 <span>million+</span></h2>
            <p>Follower Reach</p>
          </div>
          <div className="item">
            <h2>20 <span>million+</span></h2>
            <p>Views on our creator video</p>
          </div>
          <div className="item">
            <h2>100 <span>+</span></h2>
            <p>Creator Number</p>
          </div>
          <div className="item">
            <h3>
              <p>“THANK YOU” “<span>❤️❤️❤️</span>”</p>
              <p>“IT ACTUALLY WORK”</p>
              <p>“You’re really dedicated”</p>
            </h3>
            <p>The comments from the creator</p>
          </div>
        </div>
      </div>
      <div className='Creator'>
        <div className="title">
          <div className="svg">
            <LineSVGContainer />
          </div>
          <h1>Creator Feedback</h1>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          navigation={true}
          loop={true}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/avator.png').default} alt="" />
              <h3>Alex</h3>
            </div>
            <p>“Joining this agency was a game-changer for me. My community feels more connected, and I’ve seen a massive increase in interaction on my posts! ”</p>

          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/Bbgmay.png').default} alt="" />
              <h3>Bbgmay</h3>
            </div>
            <p>“Thanks for everything you are doing! Thank you Liz for getting my live access back❤️”</p>

          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/Johnny2.png').default} alt="" />
              <h3>Johnny</h3>
            </div>
            <p>“Thank you Liz it’s going be kind of hard pushing for 5 million this month 😞My team did so much these past months 🤦🏾‍♂️But I know both tried too hard!🔥”</p>

          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/TylerWave.png').default} alt="" />
              <h3>Tyler Wave</h3>
            </div>
            <p>“BlinkBloc helped me host my first birthday event, and the turnout was amazing! I’m so happy!”</p>

          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/Traiblfire.png').default} alt="" />
              <h3>Traiblfire</h3>
            </div>
            <p> “I received the leopard. 🎉And will arrange the other drop soon for a battle.
              Thanks for your help fam! ”</p>
          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/Dessiana.png').default} alt="" />
              <h3>Dessiana.M</h3>
            </div>
            <p>“Thank you! I appreciate that.🥰 I’m in A2 now, so that traffic support would definitely help!  ” </p>
          </SwiperSlide>
          <SwiperSlide className='item'>
            <div className="avator">
              <img src={require('../../../assets/img/Mia.png').default} alt="" />
              <h3>Mia Q</h3>
            </div>
            <p>“ I’ve always struggled with maintaining ppl in my livestream, but now, my fams are more active than ever, and I’ve even gained new followers interested in my content.”</p>
          </SwiperSlide>

        </Swiper>
        <a href="/creator" className='btn'>
          {/* <img src={require('../../../assets/img/join_btn.png').default} alt="" /> */}

          Join Now
        </a>
      </div>
      <div className="team">
        <div className="title">
          <div className="svg">
            <LineSVGContainer strokeColor='#ffffff' />
          </div>
          <h1>Our Partners</h1>
        </div>
        <div className="list">

          {
            !isMobile() ?
              Array(6).fill(0).map((ele, index) =>
                <div key={index} className="item">
                  <img src={[team1, team2, team3, team4, team5, team6][index]} alt="" width={'100%'} />
                </div>
              )

              : <Swiper
                slidesPerView={3.8}
                spaceBetween={24}
                modules={[Autoplay]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                className="myTeamSwiper"
              >
                {
                  Array(6).fill(0).map((ele, index) =>
                    <SwiperSlide key={index} className="item">
                      <img src={[team1, team2, team3, team4, team5, team6][index]} alt="" width={'100%'} />
                    </SwiperSlide>
                  )
                }



              </Swiper>

          }




        </div>
        <div className="join">
          <p>More than just a TikTok live agency—we connect you with the brands we partner with, so you can too! Your opportunities are limitless.</p>
          <a href="https://www.tiktok.com/t/ZS2yE8pDp/
" className='btn'>
            Join Our Community Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;