import React, { ReactNode, useEffect } from 'react';
import { isMobile } from '../../libs/userAgent';
import './Modal.scss';  // You can create this for styling

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  isSuccess: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children, isSuccess }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={isMobile() ? "mModal modal-overlay" : "modal-overlay"} onClick={onClose}>
      <div className={isSuccess ? "success modal-content" : "error modal-content"} onClick={e => e.stopPropagation()}>
        {/* <div className="modal-header">
          <h2>{title}</h2>
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
        </div> */}
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button onClick={onClose} className='submit' type="submit">
            {
              isSuccess ? "OK" : 'Resubmit'
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
