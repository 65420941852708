import { useState } from 'react';
import './index.scss'




const MobileHeader = (): JSX.Element => {



  const [isActive, setIsActive] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    setIsMenuActive(!isMenuActive)
  };

  return (
    <>
      <div className={isActive ? 'mHeader  burger--active' : 'mHeader'} >
        <div className="mHeader-wrap">
          <div className="mHeader-logo">
            <a href='/'>
              <img src={require('../../assets/img/logo.png').default} alt='Logo' />
            </a>
          </div>
          <div className="navBtn" >
            <div onClick={handleClick} className='btn' >
              <div className="top-bun"></div>
              <div className="patty"></div>
              <div className="bottom-bun"></div>
            </div>
          </div>
        </div>
      </div>



      <div className={isActive ? 'header-menu bright header--menu-open' : 'header-menu bright '}>
        <div className="header-menu-bg theme-bg--primary"></div>
        <div className="header-menu-nav">
          <div className="nav">
            <div className="item">
              <a href="/">Home</a>
            </div>
            <div className="item">
              <a href="/about">About us</a>
            </div>
            <div className="item">
              <a href="https://blinkbloc.gitbook.io/blinkbloc/">Blog</a>
            </div>
          </div>
          <div className="apply">
            <a href="/apply">
              Apply
            </a>
          </div>
          <div className="social">
            <div className="item">
              <a href="https://www.tiktok.com/@blink.bloc">
                <img src={require('../../assets/img/tt_m.png').default} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="mailTo:contact@blinkbloc.live
">
                <img src={require('../../assets/img/email_m.png').default} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default MobileHeader;