import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/pc/home';
import Header from './components/pc/header';
import { isMobile } from './libs/userAgent';
import MobileHeader from './components/mheader';
import Footer from './components/pc/footer';
import AboutComp from './components/pc/about';
import ApplyComp from './components/pc/apply';
import CreatorForm from './components/pc/applyform/creator';
import AgentForm from './components/pc/applyform/Agent';
import AmbassadorForm from './components/pc/applyform/Ambassador';
import Service from './components/Service';
import Privacypolicy from './components/privacypolicy';

function App() {

  return (
    <>
      <Router>
        {isMobile() ? <MobileHeader /> : <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutComp />} />
          <Route path="/apply" element={<ApplyComp />} />
          <Route path="/creator" element={<CreatorForm />} />
          <Route path="/agent" element={<AgentForm />} />
          <Route path="/ambassador" element={<AmbassadorForm />} />
          <Route path="/service" element={<Service />} />
          <Route path="/privacy" element={<Privacypolicy />} />
          {/* <Route path="/test" element={<TestPage />} /> */}

        </Routes>
        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
        <div className="pcFooter">
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
